import { JwtHelperService } from '@auth0/angular-jwt';
import { Injectable, inject } from '@angular/core';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AppDataService {
  baseUrl = environment.baseUrl;
  baseChirpUrl = environment.baseChirpUrl;
  baseGoatUrl = environment.baseGoatUrl;
  baseHref = environment.baseHref;
  appConfig: any;
  username: string;
  isRequestInProgress: boolean;
  showProspective: boolean;
  userId: number;
  // fixedToolbar = false;
  promotionPodType: Observable<string>;
  userFullName: string;
  loggedIn = false;
  appReady = false;
  token: string;
  // profile: Profile;
  restHeaders: any = {};

  isCSR: boolean;
  isCSRManager: boolean;
  isPartner: boolean;
  isAccountManager: boolean;
  isProgramManager: boolean;
  isAdmin: boolean;
  orgId: number | undefined;
  externalOrgCode: string | undefined;
  orgUsername: string | undefined;
  organizationLogoUrl: string | undefined;
  segmentId: number | undefined;

  isLiveAgent: boolean;
  isLiveAgentManager: boolean;
  useAllowlist: boolean;
  allowSurveyExport: any;
  requiresPasswordReset: boolean = false;

  clientIpAddress: string;

  constructor(private toastr: ToastrService) {
    this.getAppConfig();
  }

  getAppConfig = (): void => {
    fetch('./assets/app-config.json').then((resp: any) => {
      resp.json().then((data: any) => {
        this.appConfig = data;
      });
    });
  };

  // TODO: implement properly
  public onLogout(wasUserInitiated: boolean): void {
    this.loggedIn = false;
    this.appReady = false;
    this.token = null;
    this.restHeaders = {};
    if (wasUserInitiated) {
      this.toastr.info('You have been logged out. Thanks for visiting!');
    } else {
      this.toastr.warning(
        'Your session is expired. Please log in again to continue.'
      );
    }
  }

  public checkPermission(permission: string): boolean {
    const token: string = localStorage.getItem('token');
    let valid = false;

    if (token !== null && token !== 'null') {
      const jwth = new JwtHelperService();
      if (jwth.isTokenExpired(token)) {
        console.log('Found expired token: ' + token);
        const router: Router = inject(Router);
        router.navigate(['/logged-out']);
        return;
      } else {
        const dt = jwth.decodeToken(token);
        const roles = [
          'CSR',
          'PARTNER',
          'CSR_MANAGER',
          'ACCOUNT_MANAGER',
          'PROGRAM_MANAGER',
          'ADMIN',
        ];
        const requiredPermission = roles.indexOf(permission);
        dt.roles.forEach((role) => {
          if (roles.indexOf(role) >= requiredPermission) {
            valid = true;
          }
        });
      }
    }
    return valid;
  }
}
